<template>
  <div  v-loading="loading">
   <div class="card card-custom">
      <div class="card-body p-0">
         <div class="row">
            <div class="col-sm-6 col-xs-12">
               <b-form-group>
                  <b-input-group size="sm">
                     <b-form-input
                        v-model="filter"
                        type="search"
                        id="filterInput"
                        placeholder="Type to Search"
                        @keyup.enter.native="(filter)?fetchData(1):''"
                        ></b-form-input>
                     <span class="search-icon" v-if="filter" id="clear-button">
                        <i class="fas fa-times" @click="filter =''"></i>
                        <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                     </span>
                     <b-input-group-append>
                        <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                     </b-input-group-append>
                  </b-input-group>
               </b-form-group>
            </div>
            <div class="col-sm-6 col-xs-12 text-right">
               <!-- <b-button class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="createPackingslip"><i class="fa fa-plus"></i>Create Packing Slip
               </b-button> -->
            </div>
         </div>
         <div class="row">
            <div class="col-sm-12">
               <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
                 <el-table-column label="Packing #" sortable width="120" >
                     <template slot-scope="scope">
                        <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewPacking(scope.row.packing_id)">{{(scope.row.packing_no) ? scope.row.packing_no : ""}}</a></el-tag>
                     </template>
                  </el-table-column>
                  <el-table-column label="Order #" sortable width="120" >
                     <template slot-scope="scope">
                        <span>{{(scope.row.order.order_number) ? scope.row.order.order_number : ""}}</span>
                     </template>
                  </el-table-column>
                  <el-table-column label="Customer Name" sortable width="200">
                     <template slot-scope="scope">                       
                        <span v-if="scope.row.customer.first_name">{{scope.row.customer.first_name}}</span> <span v-if="scope.row.customer.last_name">{{scope.row.customer.last_name}}</span>                
                     </template>
                  </el-table-column>
                  <el-table-column label="Shipping Date" sortable width="150">
                     <template slot-scope="scope">
                        <span>{{scope.row.ship_date}}</span>                
                     </template>
                  </el-table-column>
                   <el-table-column label="Delivery date" sortable width="150" >
                     <template slot-scope="scope">
                        {{scope.row.order.delivery_date|formatDate('DD-MM-YYYY')}}                 
                     </template>
                  </el-table-column>
                  <el-table-column label="Quantity" sortable width="150" align="center">
                     <template slot-scope="scope">
                        <span>{{scope.row.total_quantity}}</span>                
                     </template>
                  </el-table-column>
                  <el-table-column label="Weight (Kg)" sortable width="150" align="center">
                     <template slot-scope="scope">
                        <span>{{scope.row.total_weight}}</span>                
                     </template>
                  </el-table-column>
                  <el-table-column label="Created date" sortable width="150" >
                     <template slot-scope="scope">
                        {{scope.row.created_at|formatDate('DD-MM-YYYY')}}                 
                     </template>
                  </el-table-column>
                  <el-table-column fixed="right" align="center" label="Actions" width="80">
                     <template slot-scope="scope">
                        <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                           <i class="flaticon2-menu-1"></i>
                           <el-dropdown-menu slot="dropdown" class="action-dropdown">
                              <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>
                              <el-dropdown-item :command="2" ><i class="fa fa-download"></i>Download</el-dropdown-item>
                              <el-dropdown-item :command="3" ><i class="fa fa-trash"></i>Deleted</el-dropdown-item>
                           </el-dropdown-menu>
                        </el-dropdown>
                     </template>
                  </el-table-column>
               </el-table>
               <div>
               </div>
            </div>
         </div>
          <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[50, 100, 500, 1000]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>
      </div>
      <v-dialog/>
   </div>
</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {changeStatus} from "@/api/order";
import {getPackingslipList, deletePacking, downloadPdf} from "@/api/packingslip";

  export default {
    name: "list-order",
    data() {
      return {
        list: [],
        items: [],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },    
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        view_type: 'packingslip'
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },    
       
        { title: "Packing Slip" },
        
      ]);
    },
    created(){
      this.fetchData(1);
    },
    methods:{
      updateStatus(supplier_id,status){
        changeStatus(supplier_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })},
      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
       if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        getPackingslipList(params).then(response => {
          this.list = response.data.data.data 
          this.currentPage = response.data.data.current_page
          this.totalRows = response.data.data.total
          this.perPage = response.data.data.per_page
          this.loading = false
          this.$set(this.list, '_showDetails', true)
          }
 
        )},

        getcustomername(customer){
            return customer.first_name+' '+customer.last_name;
        },

     onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      handlePageChange(p){
        this.fetchData(p)
      },
      handlePerPageChange(){
        this.fetchData(1)
      },
        createPackingslip(){
        this.$router.push({path:'/packingslip/add'})
      },
      viewPacking(packing_id){
           this.$router.push({path:'/packingslip/view/'+packing_id})
      },
       handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
      handleActions(evt, row)
      {
       
          if (evt === 1){
            this.$router.push({path:'/packingslip/view/'+row.packing_id})
          }
          else if (evt === 2){
                this.loading=true;
                let packingId = row.packing_no;
                downloadPdf(row.packing_id, this.view_type).then(response => {
                //console.log(response.data);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Packingslip-'+ packingId +'.pdf');
                document.body.appendChild(link);
                link.click();
                this.loading = false;
                }).catch(err => {
                console.log(err);
                this.loading = false;
                });
          }
          else if (evt === 3){
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
                deletePacking(row.packing_id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }
        },
    }
  }
</script>